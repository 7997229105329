import wx from 'jweixin-1.6.0'
import { getShareSing } from "@/api/index";
export default {
    methods: {
        /**
         * 初始化微信sdk
         */
        async initWxSdk() {
            const { data } = await getShareSing(process.env.VUE_APP_BASE_H5_API);
            wx.config({
                debug: false,
                appId: data.appId,
                timestamp: data.timestamp,
                nonceStr: data.nonceStr,
                signature: data.signature,
                jsApiList: [
                    "wx-open-launch-weapp",
                ],
                openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']})
            });
        },

        /**
         * 判断是否是在微信浏览器
         */
        isWeixinBrowser() {
            const userAgent = window.navigator.userAgent.toLowerCase()
            return userAgent.match(/MicroMessenger/i) == 'micromessenger'
        },
    }
}
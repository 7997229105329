import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,  // token
    userInfo: null, // 用户信息
  },
  getters: {
    getToken: (state) => state.token || localStorage.getItem("token"),
    getUserInfo: (state) => state.userInfo || JSON.parse(localStorage.getItem("userInfo")),
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
      localStorage.setItem("token", token)
    },
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
  },
  actions: {

  }
})

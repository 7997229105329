import Vue from "vue";
import VueRouter from "vue-router";

// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

// const routes = [
//     {
//         path: '/login',
//         name: 'LoginView',
//         meta: {
//             title: '登录',
//         },
//         component: () => import('@/views/login/index.vue'),
//     },
//     {
//         path: '/company',
//         name: 'CompanyView',
//         meta: {
//             title: '公司详情',
//         },
//         component: () => import('@/views/company/index.vue'),
//     },
//     {
//         path: '/position',
//         name: 'PositionView',
//         meta: {
//             title: '职位详情',
//         },
//         component: () => import('@/views/position/index.vue'),
//     },
//     {
//         path: '/homePage',
//         name: 'HomePageView',
//         meta: {
//             title: '用户主页',
//         },
//         component: () => import('@/views/homePage/index.vue'),
//     },
//     {
//         path: '/information',
//         name: 'InformationView',
//         meta: {
//             title: '资讯详情',
//         },
//         component: () => import('@/views/information/index.vue'),
//     },
//     {
//         path: '/production',
//         name: 'ProductionView',
//         meta: {
//             title: '作品详情',
//         },
//         component: () => import('@/views/production/index.vue'),
//     },
//     {
//         path: '/download',
//         name: 'DownloadView',
//         meta: {
//             title: '下载app',
//         },
//         component: () => import('@/views/download/index.vue'),
//     },
//     {
//         path: '/userAgreement',
//         name: 'UserAgreement',
//         meta: {
//             title: '用户协议',
//         },
//         component: () => import('@/views/agreement/userAgreement.vue'),
//     },
//     {
//         path: '/privacyAgreement',
//         name: 'PrivacyAgreement',
//         meta: {
//             title: '隐私协议',
//         },
//         component: () => import('@/views/agreement/privacyAgreement.vue'),
//     },
// ];

const routes = [
  {
    path: "/",
    redirect:"/download"
  },
  {
    path: "/download",
    name: "DownloadView",
    meta: {
      title: "下载app",
    },
    component: () => import("@/views/download/index.vue"),
  },
];

// 跳转路由后滚动到顶部
const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

export default router;

import axios from "axios";
import { Toast } from "vant";

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_APP_API, // 请求地址
  timeout: 50000, // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    Toast.loading({
      duration: 0,
      mask: true,
      message: "加载中...",
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    Toast.clear();
    const res = response.data;
    if (res.code !== 200) {
      Toast(res.msg);
      return Promise.reject(res);
    }
    // 返回成功的数据
    return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;

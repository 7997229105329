import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import mixin from './mxins/index'
import config from './utils/config'
import './utils/vant-ui'
import './utils/filter'
import '@/assets/css/index.less'


Vue.mixin(mixin)
Vue.config.productionTip = false;
Vue.prototype.$config = config;
Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";

/**
 * 时间格式化
 */
Vue.filter('dateFormat', (dateStr, format = "YYYY-MM-DD HH:mm:ss", fill = true) => {
    const date = new Date(dateStr);
    let dateNumFun
    if (fill) {
        dateNumFun = (num) => String(num).padStart(2, '0');
    } else {
        dateNumFun = (num) => String(num).padStart(1, '0');
    }
    const config = {
        YYYY: date.getFullYear(),
        MM: dateNumFun(date.getMonth() + 1),
        DD: dateNumFun(date.getDate()),
        HH: dateNumFun(date.getHours()),
        mm: dateNumFun(date.getMinutes()),
        ss: dateNumFun(date.getSeconds())
    };
    for (const key in config) {
        format = format.replace(key, config[key]);
    }
    return format;
})

/**
 * 根据出生日期获取年龄
 */
Vue.filter('getAge', (dateStr) => {
    let birthdays = new Date(dateStr)
    let currentDate = new Date();
    let age = currentDate.getFullYear() - birthdays.getFullYear() - (currentDate.getMonth() < birthdays.getMonth() || (currentDate.getMonth() == birthdays.getMonth() && currentDate.getDate() < birthdays.getDate()) ? 1 : 0);
    return age
})
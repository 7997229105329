import request from "@/utils/request";

// /**
//  * 获取验证码
//  */
// export const getCode = (params) => {
//   return request({
//     url: "/app/user/getCode",
//     method: "get",
//     params,
//   });
// };

// /**
//  * 登录
//  */
// export const login = (data) => {
//   return request({
//     url: "/app/user/verificationLogin",
//     method: "post",
//     data,
//   });
// };

/**
 * 获取公司详情
 */
export const getCompanyDetail = (data) => {
  return request({
    url: "/app/company/detail",
    method: "post",
    data,
  });
};

/**
 * 获取资讯详情
 */
export const getInformationDetail = (data) => {
  return request({
    url: "/app/article/detail",
    method: "post",
    data,
  });
};

/**
 * 获取资讯评论列表
 */
export const getInformationCommentList = (data) => {
  return request({
    url: "/app/article/getCommentList",
    method: "post",
    data,
  });
};

/**
 * 获取职位详情
 */
export const getPositionDetail = (data) => {
  return request({
    url: "/app/position/detail",
    method: "post",
    data,
  });
};

/**
 * 获取职位列表
 */
export const getPositionList = (data) => {
  return request({
    url: "/app/position/list",
    method: "post",
    data,
  });
};

/**
 * 获取作品详情
 */
export const getProductionDetail = (data) => {
  return request({
    url: "/app/opus/detail",
    method: "post",
    data,
  });
};

/**
 * 获取作品评论列表
 */
export const getProductionCommentList = (data) => {
  return request({
    url: "/app/opus/getCommentList",
    method: "post",
    data,
  });
};

/**
 * 获取作品列表
 */
export const getProductionList = (data) => {
  return request({
    url: "/app/opus/my/list",
    method: "post",
    data,
  });
};

/**
 * 获取用户信息
 */
export const getOtherUserInfo = (data) => {
  return request({
    url: "/app/user/getOtherUserInfo",
    method: "post",
    data,
  });
};

/**
 * 获取分享信息
 */
export const getShareSing = (url) => {
  return request({
    url: "/app/system/getSing?url=" + url,
    method: "get",
  });
};
